<template>
  <div>
    <el-skeleton :throttle="100" :loading="tableLoadingStatus" animated>
      <template slot="template">
        <div v-for="r in pageSize / 2" :key="r" style="padding: 5px">
          <el-skeleton-item variant="text" style="width: 29%" />&nbsp;&nbsp;
          <el-skeleton-item variant="text" style="width: 29%" />&nbsp;&nbsp;
          <el-skeleton-item variant="text" style="width: 29%" />&nbsp;&nbsp;
          <el-skeleton-item variant="text" style="width: 10%" />
        </div>
      </template>
    </el-skeleton>

    <div v-if="!tableLoadingStatus" class="table-wrapper">
      <el-table
        :data="tableDataItems"
        stripe
        :show-summary="showSummary"
        sum-text="Total"
        @selection-change="handleMultipleSelectionChange"
        max-height="600px"
      >

        <!-- TABLE ACTION  -->
        <el-table-column
          label="Actions"
          v-if="tableActionShowVisibility"
          width="110"
          fixed="left"
        >
          <template slot-scope="scope">
            <el-button
              v-if="tableActionViewVisibility"
              @click="handleViewDetails(scope.row)"
              type="text"
              icon="el-icon-view"
            ></el-button>

            <el-button
              v-if="tableActionDollarVisibility"
              @click="handleDollarBtnDetails(scope.row)"
              type="text"
            >$</el-button>

             <!-- TABLE EDIT ACTION  -->
            <el-button
              v-if="tableActionEditVisibility"
              @click="handleEdit(scope.row)"
              type="text"
              icon="el-icon-edit-outline"
              ></el-button
            >
             <!-- TABLE EDIT ACTION  -->
             <el-button
              v-if="tableFavoriteActionVisibility && scope.row.is_favourite"
              @click="removeFavorite(scope.row)"
              type="success"
              icon="el-icon-star-on"
              class="font-size"
              circle
              ></el-button
            >

             <!-- TABLE EDIT LOCK ACTION  -->
             <el-button
              v-if="tableLockActionVisibility && !scope.row.is_block"
              @click="handleLock(scope.row)"
              type="success"
              icon="el-icon-unlock"
              circle
              ></el-button
            >
             <!-- TABLE EDIT ACTION  -->
             <el-button
              v-if="tableLockActionVisibility && scope.row.is_block"
              @click="removeLock(scope.row)"
              type="warning"
              icon="el-icon-lock"
              class="font-size"
              circle
              ></el-button
            >
             <!-- TABLE EDIT ACTION  -->
             <el-button
              v-if="tableTradeActionVisibility"
              @click="handleRowClick(scope.row)"
              type="warning"
              icon="el-icon-plus"
              class="font-size"
              circle
              ></el-button
            >
            
            <!-- TABLE EDIT ACTION  -->
            <el-button
              v-if="tableFavoriteActionVisibility && !scope.row.is_favourite"
              @click="addFavorite(scope.row)"
              type="success"
              icon="el-icon-star-off"
              circle
              ></el-button
            >
              <!-- el-icon-star-off -->
            <!-- TABLE DELETE ACTION  -->
            <el-button
              v-if="tableActionDeleteVisibility"
              @click="handleDelete(scope.row)"
              type="danger"
              icon="el-icon-delete"
              circle
            ></el-button>
          </template>
        </el-table-column>

         <!-- TABLE DATA -->
        <el-table-column
          v-for="(config, key) in tableConfig"
          :label="config.label ? config.label : tableLabel(config.prop)"
          :prop="config.prop"
          :sortable="config.sortable ? 'custom' : ''"
          :width="config.width"
          :class-name="config.className"
          :key="key"
        >
          <template slot-scope="scope">

           <span v-if="config.prop == 'ch'">
              <span :class="[scope.row['ltp_up'] == true ? 'green' : 'red']"  style="margin-bottom: 10px;color:#fff;" class="capitalize">{{scope.row[config.prop]}}</span>
           </span>
           <span v-else-if="config.prop == 'ask'">
              <span :class="[ scope.row['ltp_up'] == true ? 'green' : '']"   class="capitalize">{{scope.row[config.prop]}}</span>
           </span>
           <span v-else-if="config.prop == 'bid'">
              <span :class="[ scope.row['ltp_up'] == true ? '' : 'red']"  class="capitalize">{{scope.row[config.prop]}}</span>
           </span>
           <span v-else-if="config.prop == 'ltp'">
              <span  class="capitalize" style="font-weight: bold">{{scope.row[config.prop]}}</span>
           </span>

           

            <span v-else>
                <span style="margin-bottom: 10px" class="capitalize">{{scope.row[config.prop]}}</span>
            </span>

          </template>
        </el-table-column>



        <!-- // WHEN NO DATA FOUND -->
        <div slot="empty" class="no-data">
          <svg-icon icon-name="no-data" icon-class="svg-icon-xlarge" />
          <div>Sorry! No data found.</div>
        </div>
      </el-table>

      <!-- // PAGINATION -->
      <el-pagination
        v-if="tableDataTotal"
        :total="tableDataTotal"
        :current-page.sync="tableQuery.page"
        :page-size.sync="tableQuery.limit"
        layout="prev, pager, next"
        @size-change="handlePagination()"
        @current-change="handlePagination()"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Table",
  props: {
    tableKey: {
      type: String,
      default: "",
    },
    tableConfig: {
      type: Array,
      default: function () {
        return [];
      },
    },
    tableData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    tablePagination: {
      type: Object,
      default: function () {
        return {};
      },
    },
    tableQuery: {
      type: Object,
      default: function () {
        return {};
      },
    },
    showSummary: {
      type: Boolean,
      default: false,
    },
    tableActionVisibility: {
      type: Boolean,
      default: true,
    },
    tableTradeActionVisibility: {
      type: Boolean,
      default: false,
    },
    tableActionShowVisibility: {
      type: Boolean,
      default: true,
    },
    tableFavoriteActionVisibility: {
      type: Boolean,
      default: false,
    },
    tableLockActionVisibility: {
      type: Boolean,
      default: false,
    },
    tableActionViewDetailsVisibility: {
      type: Boolean,
      default: false,
    },

    tableActionEditVisibility: {
      type: Boolean,
      default: true,
    },
    tableActionViewVisibility: {
      type: Boolean,
      default: true,
    },
    tableActionDollarVisibility: {
      type: Boolean,
      default: false,
    },
    tableHandleEditProperty: {
      // to decide which property value to emit
      type: String,
      default: null,
    },
    tableActionDeleteVisibility: {
      type: Boolean,
      default: false,
    },

    tableLoadingStatus: {
      type: Boolean,
      default: false,
    },

    tableCheckBoxVisibility: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 1,
      pageSize: 10,
      multipleSelection: [],
      tableCheckBoxVisibilityDisableKeyValueKeys: [],

    };
  },
  created() {
    if (this.tableCheckBoxVisibilityDisableKeyValue) {
      this.tableCheckBoxVisibilityDisableKeyValueKeys = Object.keys(
        this.tableCheckBoxVisibilityDisableKeyValue
      );
    }
  },
  computed: {
    tableDataItems() {
      return this.tableData.data ? this.tableData.data.item : [];
    },
    tableDataTotal() {
      console.log("total    ....  ", this.tablePagination ? this.tablePagination.total : 0)
      return this.tablePagination ? this.tablePagination.total : 0;
    },
    tableLabel() {
      return (prop) => `${this.tableKey.label}.list.${prop}`;
      // this.trans(`${this.$route.meta.module}.${this.tableKey}.list.${prop}`);
    },
  },
  methods: {
    splitedList(row){

      return row > 0 ? 'green' : 'red'

    },
    // check multiple selection on or off
    isTableCheckBoxVisibilityRowWise(row) {
      let toReturn = true;

      this.tableCheckBoxVisibilityDisableKeyValueKeys.forEach((key) => {
        if (key in row) {
          if (row[key] == this.tableCheckBoxVisibilityDisableKeyValue[key]) {
            toReturn = false;
          }
        }
      });

      return toReturn;
    },
    handleMultipleSelectionChange(val) {
      this.multipleSelection = val;
      this.$emit("multipleSelection", this.multipleSelection);
    },
    handlePagination() {
      this.$emit("pagination");
    },
    handleViewDetails(row) {
      this.$emit("view", row);
    },
    handleDollarBtnDetails(row) {
      this.$emit("dollar", row);
    },
    handleEdit(row) {
      this.$emit("edit", row.stock_id);
    },
    handleDelete(row) {
      this.$emit("delete", row);
    },
    removeFavorite(row) {
      this.$emit("removeFavorite", row);
    },
    addFavorite(row) {
      this.$emit("addFavorite", row);
    },
    removeLock(row) {
      this.$emit("removeLock", row);
    },
    handleRowClick(row) {
      this.$emit("handleRowClick", row);
    },
    handleLock(row) {
      this.$emit("lock", row);
    },
  },
};
</script>

<style scoped>
.el-input-group__append {
  background: #f3f2f7 !important;
  color: #686868 !important;
}
.green {
    padding: 10px;
    background-color: green;
    color:#fff;
}
.red {
    padding: 10px;
    background-color: red;
    color:#fff;
}

.font-size {
  font-size:22px
}
</style>
